import '../styles/Input.css'

import React, { PureComponent } from 'react'

import { getIn } from '../utils/get-set-in'

type Props = {
  structure: any
  dataKey: string
  value: string | any | number
  updateHandler: (...args: Array<any>) => any
  onChangeHandler?: (value: any) => any
  tabHandler?: (...args: Array<any>) => any
  inline?: boolean
  focus?: boolean
  postRender?: (...args: Array<any>) => any
  leaveEditState?: (...args: Array<any>) => any
}

type State = {
  height?: number
  value: string
}

export default class Input extends PureComponent<Props, State> {
  _timeout: any
  max: any
  min: any
  refInput: any
  step: any
  t0: any
  v0: any
  y0: any

  constructor(props: Props) {
    super(props)

    this.state = {
      value: props.value || '',
    }
    this._timeout = 0
  }

  componentDidMount() {
    const { inline, focus, value, onChangeHandler, structure } = this.props

    if (onChangeHandler && structure.taggable) {
      onChangeHandler(value)
    }

    window.setTimeout(() => {
      this.setHeight()
    }, 0)

    if (inline || focus) {
      window.setTimeout(() => {
        this.refInput && this.refInput.focus()
      }, 100)
    }
  }

  componentWillUnmount() {
    const { structure } = this.props
    if (structure.updateOnBlur && !structure.encoder) {
      this.updateValue()
    }
    this.removeOverlayEvents()
  }

  componentDidUpdate(prevProps: Props) {
    const { value } = this.props
    if (prevProps.value !== value && value !== this.state.value) {
      this.setState({ value })
    }
  }

  focusHandler = (event) => {
    // put the cursor at the end of the current text
    // http://stackoverflow.com/a/10576409/384938
    const el = event.target
    if (el.type !== 'number') {
      el.selectionStart = el.selectionEnd = el.value.length
    }
  }

  blurHandler = () => {
    const { structure } = this.props
    if (structure.updateOnBlur && !structure.encoder) {
      this.updateValue()
    }
  }

  changeHandler = (event) => {
    const {
      structure,
      dataKey,
      value,
      onChangeHandler,
      updateHandler,
    } = this.props
    const newValue = event.target.value

    if (onChangeHandler && structure.taggable) {
      onChangeHandler(newValue)
    }

    const preparedValue = this.prepareValue(newValue)
    this.setState({
      value: preparedValue,
      height: event.target.scrollHeight + Math.ceil(Math.random() * 10),
    })

    if (structure.updateOnBlur) {
      return
    }

    if (preparedValue !== value && updateHandler) {
      // debounce the update
      clearTimeout(this._timeout)
      this._timeout = window.setTimeout(() => {
        updateHandler(dataKey, preparedValue)
      }, 100)
    }
  }

  keyDownHandler = (event) => {
    const {
      tabHandler,
      dataKey,
      updateHandler,
      leaveEditState,
      structure,
    } = this.props
    const { value } = this.state
    const type = structure.type

    if (event.keyCode === 9 && tabHandler) {
      if (value !== this.props.value) {
        updateHandler(dataKey, value)
      }
      tabHandler(event)
    }

    if (event.keyCode === 27) {
      leaveEditState && leaveEditState()
    }

    if (event.keyCode === 13) {
      if (type === 'textarea') {
        return
      }
      leaveEditState && leaveEditState()
    }
  }

  overlayStartHandler = (event) => {
    const { structure } = this.props
    this.y0 = event.clientY
    this.t0 = Date.now()
    this.v0 = parseFloat(this.refInput.value)
    this.min = -1e12
    this.max = 1e12
    this.step = 1

    if (structure && structure.options) {
      const opts = structure.options
      this.min = opts.min !== undefined ? opts.min : this.min
      this.max = opts.max !== undefined ? opts.max : this.max
      this.step = opts.step !== undefined ? opts.step : this.step
    }

    document.body.addEventListener('mousemove', this.overlayMoveHandler)
    document.body.addEventListener('mouseup', this.overlayEndHandler)
    document.body.addEventListener('mouseleave', this.overlayEndHandler)
  }

  overlayMoveHandler = (event) => {
    const dy = (this.y0 - event.clientY) * 0.5
    const precision = Math.max(Math.ceil(Math.log10(1 / this.step)), 0)
    let v = this.roundTo(this.v0 + dy * this.step, this.step)

    if (this.min || this.max) {
      v = Math.min(Math.max(v, this.min), this.max)
    }

    this.changeHandler({ target: { value: v.toFixed(precision) } })
  }

  overlayEndHandler = () => {
    this.removeOverlayEvents()

    if (Date.now() - this.t0 < 200) {
      this.refInput.focus()
    }
  }

  removeOverlayEvents() {
    document.body.removeEventListener('mousemove', this.overlayMoveHandler)
    document.body.removeEventListener('mouseup', this.overlayEndHandler)
    document.body.removeEventListener('mouseleave', this.overlayEndHandler)
  }

  setHeight() {
    this.refInput && this.setState({ height: this.refInput.scrollHeight })
  }

  prepareValue(v: string) {
    const { structure, value } = this.props
    let val: any = v

    // don't parseFloat when we've not yet added a number after the decimal point or a minus sign,
    // otherwise the parseFloat strips it!
    const numeric =
      structure.type === 'number' ||
      getIn(structure, ['options', 'data-type']) === 'number'
    if (numeric && /[^.]$/.test(val) && /^[-]?[\d.]+$/.test(val)) {
      val = parseFloat(v)
    } else if (structure.type === 'param') {
      val = (val || '')
        .toString()
        .replace(/[^\da-z.%-]/gi, '') // remove all none CSS valid value characters
        .replace(/$[^\d-]/g, '') // remove leading characters that aren't numeric
        .toLowerCase()
    } else {
      val = val || ''
    }
    if (structure.formatter) {
      val = structure['formatter'](val)
    }

    if (structure.encoder) {
      const srcValue = value || structure.value || ''
      val = this.encodeValue(
        structure.encoder,
        structure.decoder,
        srcValue,
        structure.valueIndex,
        val
      )
    }

    return val
  }

  encodeValue(encode, decode, value, idx, val) {
    let vals

    if (idx > -1) {
      vals = decode(value)
      vals[idx] = val
    } else {
      vals = val
    }

    return encode(vals)
  }

  decodeValue(decode, value, idx) {
    const vals = decode(value)
    return idx > -1 ? vals[idx] || '' : vals
  }

  roundTo(val, t) {
    return t * Math.round(val / t)
  }

  updateValue = () => {
    const { dataKey, value, updateHandler, focus } = this.props
    let val = this.prepareValue(this.state.value)

    if (typeof val === 'string') {
      val = val.trim()
    }

    if (val !== value || focus) {
      updateHandler(dataKey, val)
    }
  }

  setRefInput = (el) => (this.refInput = el)

  render() {
    const { structure, dataKey, postRender, inline } = this.props
    const { value } = this.state

    let id = ('' + dataKey).replace(/[.~@!]/g, '-')
    id += structure.valueIndex || ''
    const htmlFor = `input-${id}`
    const type = structure.type
    let className = `ui-i ui-field ui-input ui-input-${type} field-${id}`

    if (inline) {
      className += ' inline'
    }

    const style: React.CSSProperties = {}
    if (structure.width) {
      style.width = structure.width
    }

    let opts: any = {}
    if (structure.options) {
      opts = structure.options
    }

    let field, label, val

    if (structure.encoder) {
      val = this.decodeValue(structure.decoder, value, structure.valueIndex)
    } else {
      val = value
    }

    let overlay
    if (/abstract|textarea/.test(type)) {
      field = (
        <textarea
          value={val}
          onChange={this.changeHandler}
          onFocus={this.focusHandler}
          onBlur={this.blurHandler}
          onKeyDown={this.keyDownHandler}
          id={htmlFor}
          ref={this.setRefInput}
          data-cy={htmlFor}
          {...opts}
        />
      )
    } else {
      if (type === 'number') {
        val = val || 0
        overlay = (
          <span
            className="ui-input-overlay"
            onMouseDown={this.overlayStartHandler}
          />
        )
      } else if (val === undefined) {
        val = ''
      }
      field = (
        <input
          type={type === 'param' ? 'text' : type}
          value={val}
          onChange={this.changeHandler}
          onKeyDown={this.keyDownHandler}
          onFocus={this.focusHandler}
          onBlur={this.blurHandler}
          id={htmlFor}
          data-cy={htmlFor}
          ref={this.setRefInput}
          {...opts}
        />
      )
    }

    if (structure.label && !inline && !structure.hideLabel) {
      label = (
        <label htmlFor={htmlFor} className="ui-label" title={opts.title || ''}>
          {structure.label}
        </label>
      )
    }

    if (!label) {
      className += ' no-label'
    }

    let unit
    if (structure.unit) {
      unit = <span className="ui-unit">{structure.unit}</span>
    }

    if (structure.className) {
      className += ` ${structure.className}`
    }

    const output = (
      <section className={className} style={style} key={htmlFor}>
        {label}
        {field}
        {unit}
        {overlay}
      </section>
    )

    return postRender ? postRender(structure, output, value) : output
  }
  /* eslint-enable complexity */
}
